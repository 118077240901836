<template>
  <OverviewWrapper>
    <v-row>
      <v-col cols="12" lg="8" order="1" order-lg="0">
        <AppCard :title="$t('All Time Reports')" title-actions>
          <template #titleActions>
            <div class="d-flex flex-column flex-md-row align-end align-md-center justify-end">
              <v-text-field
                v-model="search"
                class="mb-4 mb-md-0 mr-md-4"
                outlined
                hide-details="auto"
                dense
                type="text"
                label="Search Time Report"
                style="min-width: 150px; max-width: 200px"
                prepend-inner-icon="mdi-magnify"
              />
              <v-btn to="TimeReports/create" color="secondary" rounded dark>
                <v-icon>mdi-plus</v-icon>
                <span class="d-none d-sm-inline">Time Report erstellen</span>
              </v-btn>
            </div>
          </template>

          <AppPagination v-if="pagination" :pagination="pagination" location="top" />

          <v-data-table
            class="v-data-table__overview"
            :headers="headers"
            :items="timeReports"
            :page="pagination ? pagination.page : 1"
            :items-per-page="pagination ? pagination.perPage : 20"
            :loading="pending.getTimeReports"
            hide-default-footer
          >
            <template #[`item.client`]="{ item }">
              <b>{{ item.client.contact_handle.organisation }}</b>
              {{ item.client.contact_handle.firstname }}
              {{ item.client.contact_handle.lastname }}
            </template>

            <template #[`item.created`]="{ item }">
              {{ item.created | moment('DD.MM.YYYY') }}
            </template>

            <template #[`item.actions`]="{ item }">
              <OverviewActions
                type="timeReports"
                :item-id="item.id"
                info
                edit
                remove
                :to-invoice="!item.invoice_id"
                @onRemove="remove"
                @onToInvoice="toInvoice"
              />
            </template>
          </v-data-table>

          <AppPagination v-if="pagination" :pagination="pagination" />
        </AppCard>
      </v-col>
      <v-col cols="12" lg="4" order="0" order-lg="1">
        <AppCard :loading="pending.getTimeReports" :title="$t('Customers not billed')" :text="$t('Current month')">
          <v-chip-group v-if="unbilledCustomers.length >= 1" column>
            <v-chip
              v-for="client in unbilledCustomers"
              :key="client.id"
              :to="{ name: 'clients-item', params: { id: client.id } }"
            >
              {{ client.contact_handle.firstname }} {{ client.contact_handle.lastname }}
            </v-chip>
          </v-chip-group>
          <p v-else>All customers accounted</p>
        </AppCard>
      </v-col>
    </v-row>
  </OverviewWrapper>
</template>

<script>
import { defineComponent, ref, computed, watch } from '@vue/composition-api'

import AppCard from '@/components/UI/AppCard.vue'
import AppPagination from '@/components/UI/AppPagination.vue'
import OverviewActions from '@/components/UI/OverviewActions.vue'
import OverviewWrapper from '@/layouts/OverviewWrapper.vue'

export default defineComponent({
  name: 'ViewTimeReports',
  components: {
    AppCard,
    AppPagination,
    OverviewActions,
    OverviewWrapper,
  },
  setup(props, { root: { $store, $route, $router }, root }) {
    const page = ref(1)
    const search = ref(null)
    const headers = [
      {
        sortable: false,
        text: 'Client',
        value: 'client',
      },
      {
        sortable: false,
        text: 'Year',
        value: 'year',
      },
      {
        sortable: false,
        text: 'Month',
        value: 'month',
      },
      {
        sortable: false,
        text: 'Created',
        value: 'created',
      },
      {
        sortable: false,
        text: 'Actions',
        value: 'actions',
        align: 'right',
      },
    ]

    const activeQuery = computed(_ => root.$route.query)

    // store
    const pending = computed(_ => $store.state.timeReports.pending)
    const timeReports = computed(_ => $store.state.timeReports.timeReports)
    const unbilledCustomers = computed(_ => $store.state.timeReports.unbilledCustomers)
    const pagination = computed(_ => $store.state.timeReports.pagination)
    const getTimeReports = payload => $store.dispatch('timeReports/getTimeReports', payload)
    const deleteTimeReport = reportId => $store.dispatch('timeReports/deleteTimeReport', reportId)
    const createInvoiceFromTimeReport = reportId => $store.dispatch('timeReports/createInvoiceFromTimeReport', reportId)
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    page.value = $route.query.page || page.value
    getTimeReports({ page: page.value })

    watch(activeQuery, value => {
      page.value = value.page ? value.page : 1
      clearTimeout(delayTimer)
      getTimeReportsWithParams()
    })

    const delayTimer = ref(null)
    watch(search, _ => {
      clearTimeout(delayTimer.value)
      delayTimer.value = setTimeout(() => {
        page.value = 1
        getTimeReportsWithParams()
      }, 500)
    })

    const getTimeReportsWithParams = _ => {
      const params = {
        page: page.value,
      }

      if (search.value) {
        params.search = search.value
      }

      getTimeReports(params)
    }

    const remove = id => {
      if (confirm('Sicher, dass der Time Report gelöscht werden soll?')) {
        if (pending.value.deleteTimeReport) {
          return
        }
        deleteTimeReport(id)
          .then(_ => {
            addToast({
              msg: 'Time Report erfolgreich gelöscht',
              type: 'success',
            })

            if (pagination.value.current === 1 && page.value !== 1) {
              $router.push({ query: { page: page.value - 1 } })
            } else {
              getTimeReportsWithParams()
            }
          })
          .catch(error => {
            addToast({
              msg: 'Löschung fehlgeschlagen',
              type: 'error',
            })
          })
      }
    }

    const toInvoice = id => {
      if (confirm('Sicher, dass eine Rechnung erstellt werden soll?')) {
        if (pending.value.reportToInvoice) {
          return
        }
        createInvoiceFromTimeReport(id)
          .then(_ => {
            addToast({
              msg: 'Rechnung erfolgreich erstellt',
              type: 'success',
              // TODO: add link to invoice to toast
            })

            getTimeReports(page.value)
          })
          .catch(error => {
            addToast({
              msg: 'Rechnung erstellung fehlgeschlagen',
              type: 'error',
            })
          })
      }
    }

    return {
      pending,
      search,
      timeReports,
      unbilledCustomers,
      headers,
      pagination,
      remove,
      toInvoice,
    }
  },
}) //
</script>
